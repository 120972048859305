import useUser from "~/lib/useUser";
import axios from "axios";
import { env } from "~/env.mjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export enum AvatarProvider {
  ALL = "ALL",
  AVATURN = "AVATURN",
  READY_PLAYER_ME = "READY_PLAYER_ME",
  CITIXENS = "CITIXENS",
  OG_CITIXENS = "OG_CITIXENS",
}

export interface AvatarsUrlItem {
  id?: string;
  glb?: string;
  png: string;
  isFavorite?: boolean;
  tokenId?: string;
  contractAddress?: string;
}

export interface Avatar extends AvatarsUrlItem {
  provider?: AvatarProvider;
}

export interface AvatarsUrlResponse {
  avatarsUrl: AvatarsUrlItem[];
}
export const useOffChainAvatars = (
  provider: AvatarProvider,
  favorite: boolean
) => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";

  const queryFn = async () => {
    const params: { provider?: string; favorite?: boolean } = {};
    if (provider === AvatarProvider.AVATURN)
      params.provider = AvatarProvider.AVATURN;
    if (provider === AvatarProvider.READY_PLAYER_ME)
      params.provider = AvatarProvider.READY_PLAYER_ME;
    if (favorite) params.favorite = true;

    const { data } = await axios.get<AvatarsUrlResponse>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/user/list/avatars`,
      {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  };

  const queryKey = [
    "avatars",
    "offChain",
    provider,
    favorite ? "favorite" : "ignore-favorite",
  ];
  const haveValidProvider = [
    AvatarProvider.ALL,
    AvatarProvider.AVATURN,
    AvatarProvider.READY_PLAYER_ME,
  ].includes(provider);
  const queryObj = useQuery({
    queryKey,
    queryFn,
    enabled: !!accessToken && haveValidProvider,
  });
  return {
    ...queryObj,
    isLoading: haveValidProvider ? queryObj.isLoading : false,
  };
};

export const useCitixens = (provider: AvatarProvider, favorite: boolean) => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";

  const queryFn = async () => {
    const params: { favorite?: boolean; provider?: string } = {};
    if (favorite) params.favorite = true;
    params.provider = provider;

    const { data } = await axios.get<{
      ogCitixenList?: AvatarsUrlItem[];
      citixenList?: AvatarsUrlItem[];
    }>(`${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/user/list/citixen`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [
    "avatars",
    "onChain",
    provider,
    favorite ? "favorite" : "ignore-favorite",
  ];
  const queryObj = useQuery({
    queryKey,
    queryFn,
    enabled:
      !!accessToken &&
      [
        AvatarProvider.ALL,
        AvatarProvider.OG_CITIXENS,
        AvatarProvider.CITIXENS,
      ].includes(provider),
  });
  return queryObj;
};

export const useOnChainAvatars = (
  provider: AvatarProvider,
  favorite: boolean
) => {
  const ogCitixen = useCitixens(AvatarProvider.OG_CITIXENS, favorite);
  const citixen = useCitixens(AvatarProvider.CITIXENS, favorite);

  return {
    citixenList: citixen.data?.citixenList,
    ogCitixenList: ogCitixen.data?.ogCitixenList,
    isLoading: ogCitixen.isLoading || citixen.isLoading,
    isFetching: ogCitixen.isFetching || citixen.isFetching,
  };
};

export const useAvatars = (provider: AvatarProvider, favorite: boolean) => {
  const offChainAvatarsRes = useOffChainAvatars(provider, favorite);
  const offChainAvatars = offChainAvatarsRes.data?.avatarsUrl;
  const onChainAvatarsRes = useOnChainAvatars(provider, favorite);

  const data: Avatar[] = [];
  if (
    [
      AvatarProvider.ALL,
      AvatarProvider.AVATURN,
      AvatarProvider.READY_PLAYER_ME,
    ].includes(provider)
  ) {
    const items = offChainAvatars?.map((avatar) => ({
      ...avatar,
      provider: avatar.glb?.includes("ready_player_me")
        ? AvatarProvider.READY_PLAYER_ME
        : avatar?.glb?.includes("avaturn")
        ? AvatarProvider.AVATURN
        : undefined,
    }));
    if (items?.indexOf) data.push(...items);
  }

  if ([AvatarProvider.ALL, AvatarProvider.CITIXENS].includes(provider)) {
    const items = onChainAvatarsRes?.citixenList?.map((avatar) => ({
      ...avatar,
      provider: AvatarProvider.CITIXENS,
    }));
    if (items?.indexOf) data.push(...items);
  }

  if ([AvatarProvider.ALL, AvatarProvider.OG_CITIXENS].includes(provider)) {
    const items = onChainAvatarsRes?.ogCitixenList?.map((avatar) => ({
      ...avatar,
      provider: AvatarProvider.OG_CITIXENS,
    }));
    if (items?.indexOf) data.push(...items);
  }

  return {
    data,
    isLoading: offChainAvatarsRes.isLoading || onChainAvatarsRes.isLoading,
    isFetching: offChainAvatarsRes.isFetching || onChainAvatarsRes.isFetching,
  };
};

export const useMutateFavoriteOffChainAvatar = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";

  const mutationFn = async ({
    avatarId,
    favourite,
  }: {
    avatarId: string;
    favourite: boolean;
  }) => {
    await axios.patch(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/user/favorite-avatar
`,
      {
        avatarId,
        favourite,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      return queryClient.invalidateQueries(["avatars"]);
    },
  });
};

export const useMutateFavoriteOnChainAvatar = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";

  const mutationFn = async ({
    tokenId,
    contractAddress,
    avatarProvider,
    favourite,
  }: {
    tokenId: string;
    contractAddress: string;
    avatarProvider: AvatarProvider.CITIXENS | AvatarProvider.OG_CITIXENS;
    favourite: boolean;
  }) => {
    await axios.patch(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/user/favorite-citixen-avatar
`,
      {
        tokenId,
        contractAddress,
        avatarProvider,
        favourite,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      return queryClient.invalidateQueries(["avatars"]);
    },
  });
};
