import { ethers } from "ethers";
import { env } from "~/env.mjs";
import fetchJson from "~/lib/fetchJson";
import { type AuthenticationService } from "~/type/Auth";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { AvatarProvider } from "~/data/useAvatars";

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const isBrowser = () => typeof window !== "undefined";

export const truncateAddress = (text: string, length = 6) => {
  return `${text.slice(0, length)}...${text.slice(-length)}`;
};

export const sanitizedImageURL = (url: string) => {
  if (!url) return "/images/placeholder.png";

  if (url.startsWith("data:image")) {
    return url;
  }

  if (url?.startsWith("https://res.cloudinary.com/")) {
    return url;
  }

  let sanitizedURL = url;

  sanitizedURL = sanitizedURL.trim();

  if (!url?.startsWith("ipfs://") && !url?.includes("/ipfs/")) {
    return `https://res.cloudinary.com/${env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/fetch/q_auto/${sanitizedURL}`;
  }

  if (url?.includes("/ipfs/")) {
    const ipfsHash = url.split("/ipfs/")[1];
    sanitizedURL = ipfsHash || "";
  }

  sanitizedURL = sanitizedURL.replace("ipfs://", "");

  return `https://res.cloudinary.com/${env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/q_auto/${env.NEXT_PUBLIC_IPFS_CLOUDINARY_MAPPING}/${sanitizedURL}`;
};

export const sanitizedVideoUrl = (url: string) => {
  if (!url) return "";

  if (url?.startsWith("https://res.cloudinary.com/")) {
    return url;
  }

  let sanitizedURL = url;

  sanitizedURL = sanitizedURL.trim();

  if (!url?.startsWith("ipfs://") && !url?.includes("/ipfs/")) {
    return `https://res.cloudinary.com/${env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/video/fetch/q_auto/${sanitizedURL}`;
  }

  if (url?.includes("/ipfs/")) {
    const ipfsHash = url.split("/ipfs/")[1];
    sanitizedURL = ipfsHash || "";
  }

  sanitizedURL = sanitizedURL.replace("ipfs://", "");

  return `https://res.cloudinary.com/${env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/video/upload/q_auto/${env.NEXT_PUBLIC_IPFS_CLOUDINARY_MAPPING}/${sanitizedURL}`;
};

export const ipfsHttpGateway = (url: string) => {
  if (!url) return "";

  let sanitizedURL = url;

  if (url?.startsWith("ipfs://")) {
    sanitizedURL = url.replace("ipfs://", env.NEXT_PUBLIC_IPFS_GATEWAY);
  }
  //check if image contains ipfs/
  else if (url?.includes("ipfs/")) {
    //get string after ipfs/
    const ipfsHash = url.split("ipfs/")[1];
    sanitizedURL = ipfsHash
      ? `${env.NEXT_PUBLIC_IPFS_GATEWAY}${ipfsHash}`
      : url;
  }

  sanitizedURL = sanitizedURL.trim();

  return sanitizedURL;
};

export const formatUnixTime = (unixTime: number): string => {
  const currentTime = Math.floor(Date.now() / 1000);
  const secondsAgo = currentTime - unixTime;

  if (secondsAgo < 60 * 60) {
    // If less than 1 hour ago
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo}M`;
  } else if (secondsAgo < 60 * 60 * 24) {
    const hoursAgo = Math.floor(secondsAgo / (60 * 60));
    return `${hoursAgo}H`;
  } else {
    const daysAgo = Math.floor(secondsAgo / (60 * 60 * 24));
    return `${daysAgo}D`;
  }
};

export const formatTime = (date: Date): string => {
  const currentTime = new Date(); // Get the current time as a Date object
  const millisecondsAgo = currentTime.getTime() - date.getTime(); // Calculate how many milliseconds ago the given time was

  if (millisecondsAgo < 60 * 60 * 1000) {
    // If less than 1 hour ago
    const minutesAgo = Math.floor(millisecondsAgo / (60 * 1000)); // Calculate how many minutes ago
    return `${minutesAgo}M`; // Format and return string
  } else if (millisecondsAgo < 24 * 60 * 60 * 1000) {
    // If less than 1 day ago
    const hoursAgo = Math.floor(millisecondsAgo / (60 * 60 * 1000)); // Calculate how many hours ago
    return `${hoursAgo}H`; // Format and return string
  } else {
    // If more than 1 day ago
    const daysAgo = Math.floor(millisecondsAgo / (24 * 60 * 60 * 1000)); // Calculate how many days ago
    return `${daysAgo}D`; // Format and return string
  }
};

export const explorerURL = (chainId: string) => {
  //if in hex, change to decimal string
  if (chainId.startsWith("0x")) {
    chainId = parseInt(chainId, 16).toString();
  }

  switch (chainId) {
    case "1":
      return "https://etherscan.io";
    case "5":
      return "https://goerli.etherscan.io";
    case "11155111":
      return "https://sepolia.etherscan.io";
    case "56":
      return "https://bscscan.com";
    case "137":
      return "https://polygonscan.com";
    case "80001":
      return "https://mumbai.polygonscan.com";
    case "43114":
      return "https://cchain.explorer.avax.network";
    case "43113":
      return "https://cchain.explorer.avax-test.network";
    default:
      return "https://etherscan.io";
  }
};

export const waxExplorerURL = (chainId: string) => {
  switch (chainId) {
    case "testnet":
      return "https://wax-test.bloks.io";
    default:
      return "https://wax.bloks.io";
  }
};

export const marketplaceURL = (
  chainId: string,
  tokenAddress = "",
  tokenId = ""
): Record<string, string> => {
  //if in hex, change to decimal string
  if (chainId.startsWith("0x")) {
    chainId = parseInt(chainId, 16).toString();
  }

  switch (chainId) {
    case "5":
      return {
        opensea: `https://testnets.opensea.io/${
          !!tokenAddress ? `assets/goerli/${tokenAddress}/${chainId}` : ""
        }`,
        looksrare: `https://goerli.looksrare.org/${
          !!tokenAddress ? `collections/${tokenAddress}/${tokenId}` : ""
        }`,
        x2y2: `https://goerli.x2y2.io/${
          !!tokenAddress ? `eth/${tokenAddress}/${tokenId}` : ""
        }`,
      };
    case "11155111":
      return {
        opensea: `https://testnets.opensea.io/${
          !!tokenAddress ? `assets/sepolia/${tokenAddress}/${chainId}` : ""
        }`,
        looksrare: `https://sepolia.looksrare.org//${
          !!tokenAddress ? `collections/${tokenAddress}/${tokenId}` : ""
        }`,
      };
    case "1":
    default:
      return {
        opensea: `https://opensea.io/${
          !!tokenAddress ? `assets/ethereum/${tokenAddress}/${tokenId}` : ""
        }`,
        looksrare: `https://looksrare.org/${
          !!tokenAddress ? `collections/${tokenAddress}/${tokenId}` : ""
        }`,
        x2y2: `https://x2y2.io/${
          !!tokenAddress ? `eth/${tokenAddress}/${tokenId}` : ""
        }`,
        blur: `https://blur.io/${
          !!tokenAddress ? `asset/${tokenAddress}/${tokenId}` : ""
        }`,
      };
  }
};

export const getTypeOfEvmTransfer = (
  from: string,
  to: string,
  minter: string
) => {
  if (to === ethers.constants.AddressZero) return "burn";
  if (from === ethers.constants.AddressZero) {
    if (to.toLowerCase() === minter) return "mint";
    else return "airdrop";
  }

  return "transfer";
};

export const gweiToEth = (gwei: string) => {
  const wei = ethers.utils.formatEther(gwei);

  const w = wei.split(".")[0]; //for some reason it formatted with decimal point

  return ethers.utils.formatEther(w ? w : "0");
};

export const getLoginUrl = (
  service: AuthenticationService,
  redirectPage?: string,
  referrer?: string
) => {
  return `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/auth/${service}?state=${
    env.NEXT_PUBLIC_AUTH_REDIRECT_URL
  }${redirectPage ? redirectPage : ""}${referrer ? `%26r=${referrer}` : ""}`;
};

export const getAppLoginUrl = (service: AuthenticationService) => {
  return `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/auth/${service}?state=${env.NEXT_PUBLIC_APP_AUTH_REDIRECT_URL}`;
};

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const getYoutubeVideoId = (url: string) => {
  if (url === null) return "";
  const results = url.match("[\\?&]v=([^&#]*)");
  const videoId = results === null ? url : results[1];
  return videoId || "";
};

export const getYoutubeThumbnail = function (
  url: string,
  size = "big"
): string {
  const videoId = getYoutubeVideoId(url);
  if (size === "small") return `https://img.youtube.com/vi/${videoId}/2.jpg`;
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

export const getYoutubeMetadata = async (url: string) => {
  const videoId = getYoutubeVideoId(url);

  return await fetchJson<{ title: string }>(
    `https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const getFavicon = async (url: string) => {
  return await fetchJson<{ favicon: string }>(
    `/api/favicons?sz=32&domain_url=${url}`
  );
};

export function getDomainFromURL(url: string): string | null {
  try {
    const parsedURL = new URL(url);
    return parsedURL.hostname;
  } catch (error) {
    // Invalid URL
    return null;
  }
}

export const IsStringArray = (arr: unknown[]) => {
  if (Array.isArray(arr)) {
    const isStringArray =
      arr.length > 0 &&
      arr.every((value) => {
        return typeof value === "string";
      });

    return isStringArray;
  }
  return false;
};

export const copyToClipboard = async (text: string) => {
  if (text) {
    await navigator.clipboard.writeText(text);
  }
};

export function excludeProps<
  T extends Partial<Record<K, unknown>>,
  K extends keyof T
>(type: T, keys: K[]): Omit<T, K> {
  return Object.fromEntries(
    Object.entries(type).filter(([key]) => !keys.includes(key as K))
  ) as Omit<T, K>;
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getZendeskLoginUrl = (service: AuthenticationService) => {
  return `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/auth/${service}?state=zendesk`;
};

export function validateEmail(email: string): boolean {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

export function validatePassword(password: string): boolean {
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\-\+\=\{\}\[\]\|:;"'<>,\.\?/])[A-Za-z\d!@#\$%\^&\*\(\)_\-\+\=\{\}\[\]\|:;"'<>,\.\?/]{8,}$/;
  return passwordPattern.test(password);
}

export function validatePlayFabId(playFabId: string): boolean {
  const playFabIdPattern = /^[0-9A-Fa-f]{15,16}$/;
  return playFabIdPattern.test(playFabId);
}

export function validateAddress(address: string): boolean {
  const addressRegex = /^0[x,X][a-fA-F0-9]{40}$/gm;
  return addressRegex.test(address);
}

export function validateWaxAddress(address: string): boolean {
  const addressRegex = /^[a-zA-Z1-5.]{3,12}$/gm;
  return addressRegex.test(address);
}

export function validateYouTubeUrl(url: string): boolean {
  const pattern =
    /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([^&?/ ]{11})/;
  const match = url.match(pattern);
  return !!match;
}

export function validateValidURL(url: string): boolean {
  // Regular expression pattern for URL validation
  const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;

  return urlPattern.test(url);
}

export function groupBy<T>(
  list: T[],
  property: keyof T
): { [key: string]: T[] } {
  const grouped: { [key: string]: T[] } = {};

  for (const item of list) {
    const key = String(item[property]);
    grouped[key] = grouped[key]?.concat(item) || [item];
  }

  return grouped;
}

export const getProductTypeViaUrl = (pathname: string) => {
  const type = (pathname.split("/") || [])[2];
  if (type === "districts") return "districts";
  if (type === "apartments") return "apartment";
  return "";
};

export const isFileType = (fileName: string, videoExtensions: string[]) => {
  const fileExtension = fileName?.slice(-5)?.toLowerCase();
  if (!fileExtension) return false;

  return videoExtensions.some((extension) => fileExtension.endsWith(extension));
};

export const isVideoFile = (fileName: string) =>
  isFileType(fileName, [
    ".mp4",
    ".avi",
    ".mov",
    ".mkv",
    ".wmv",
    ".flv",
    ".webm",
  ]);

export const is3dFile = (fileName: string) =>
  isFileType(fileName, [".fbx", ".glb"]);

export async function downloadFileAndConvertToBase64(
  url: string
): Promise<string | undefined> {
  try {
    const response = await fetch(url);

    if (response.status === 200) {
      const arrayBuffer = await response.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const base64String = buffer.toString("base64");
      return `data:application/octet-stream;base64,${base64String}`;
    } else {
      console.error(`Failed to download file. Status code: ${response.status}`);
      return undefined;
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    return undefined;
  }
}

export async function downloadFileAndConvertToFile(
  url: string,
  fileName = "file"
): Promise<{ file: File; blob: Blob } | undefined> {
  try {
    const response = await fetch(url);
    if (response.status === 200) {
      const blob = await response.blob();
      // Create a File object from the Blob
      return {
        file: new File([blob], fileName, { type: blob.type }),
        blob,
      };
    } else {
      console.error(`Failed to download file. Status code: ${response.status}`);
      return undefined;
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    return undefined;
  }
}

export const fileToDataUri = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event?.target?.result as string);
    };
    reader.readAsDataURL(file);
  });

// Due to openAI only take image that have format [rgb,rgba] convert it to valid format to avoid error
export const standardizeAIInputImage = (
  dataUri: string,
  fileName: string
): Promise<File> =>
  new Promise((resolve) => {
    const image = new Image();
    image.src = dataUri;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      if (!ctx) throw new Error();
      ctx.drawImage(image, 0, 0);

      // Convert the canvas data to Blob
      canvas.toBlob((canvasBlob) => {
        if (!canvasBlob) throw new Error();
        // Create a File object from the Blob
        const file = new File([canvasBlob], fileName, {
          type: "image/png",
        });
        resolve(file);
      }, "image/png");
    };
  });

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const parseModelLink = (
  modelLink: string
): {
  modelId?: string;
  modelProvider?: AvatarProvider;
} => {
  if (modelLink?.includes("readyplayer.me")) {
    const regex = /\/([\w\d]+)\.glb.*$/;
    const match = modelLink.match(regex);
    return {
      modelId: match?.[1],
      modelProvider: AvatarProvider.READY_PLAYER_ME,
    };
  }

  if (modelLink?.includes("avaturn.me")) {
    const regex = /\/exports\/([0-9a-fA-F-]+)\/model/;
    const match = modelLink.match(regex);
    return {
      modelId: match?.[1],
      modelProvider: AvatarProvider.AVATURN,
    };
  }

  return {};
};

export const getCurrentWeekNumber = () => {
  let d = new Date();

  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(
    ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  // Return week number
  return weekNo;
};

export function shortenAddress(address: string): string {
  // Check if address is valid
  if (
    typeof address !== "string" ||
    !address.startsWith("0x") ||
    address.length !== 42
  ) {
    throw new Error("Invalid Ethereum address");
  }

  // Shorten the address
  const prefix = address.slice(0, 6);
  const suffix = address.slice(-6);

  return prefix + "..." + suffix;
}

export function generateRandomBlockchainAddress(shorten = false): string {
  const length = 40;
  const characters = "0123456789abcdef";
  let address = "0x";
  for (let i = 0; i < length; i++) {
    address += characters[Math.floor(Math.random() * characters.length)];
  }
  return shorten ? shortenAddress(address) : address;
}

export const convertToTitleCase = (str: string) => {
  if (!str) {
    return "";
  }
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

export const loadImage = (
  file: File
): Promise<{
  width: number;
  height: number;
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) {
        reject(new Error("Failed to read file"));
        return;
      }

      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = (error) => reject(error);
      img.src = e.target?.result as string;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export function chunk<T>(array?: T[], size = 1) {
  if (!array) return [];
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}
