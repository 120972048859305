import useSWR from "swr";
import { type User } from "~/pages/api/auth/user";

export default function useUser({} = {}) {
  const {
    data: user,
    mutate: mutateUser,
    isLoading,
  } = useSWR<User>("/api/auth/user");

  return { user, mutateUser, isLoading };
}
